(function () {
    angular.module('informaApp')
        .component('boxPlotChart', {
            templateUrl: 'components/charts/box-plot-chart/template.html',
            controller: ['BoxPlotChartCreator', '$element', 'ConstantsSvc', '$scope', BoxPlotChartController],
            bindings: {
                source: '<',
                hiddenPhases: '<',
                options: '<'
            }
        });

    function BoxPlotChartController(BoxPlotChartCreator, $element, ConstantsSvc, $scope) {
        var vm = this;

        vm.offsetData = 0;
        vm.itemsOnChart = 3;

        vm.canScrollLeft = false;
        vm.canScrollRight = false;

        new ResizeSensor($element.find('.charty'), () => {
            createChart($element, vm);
        });

        this.$onInit = function () {
            createChart($element, vm);
            updateScrollVisibilities();
        };

        this.$onDestroy = () => {
            if (this.boxPlotChart) {
                this.boxPlotChart.destroy();
            }
        };

        this.$onChanges = function (changes) {
            if (changes.hiddenPhases && changes.hiddenPhases.currentValue || changes.source && changes.source.currentValue) {
                createChart($element, vm);
                return;
            }

            if (changes.options && vm.boxPlotChart) {
                if (changes.options.currentValue.showNumbers) {
                    vm.boxPlotChart.showLabels();
                } else {
                    vm.boxPlotChart.hideLabels();
                }
            }
        };

        vm.scrollBackward = function () {
            if (changeOffset(vm, -vm.itemsOnChart)) {
                updateScrollVisibilities();
                createChart($element, vm);
            }
        };

        vm.scrollForward = function () {
            if (changeOffset(vm, vm.itemsOnChart)) {
                updateScrollVisibilities();
                createChart($element, vm);
            }
        };

        function updateScrollVisibilities() {
            vm.canScrollRight = vm.source.length - vm.offsetData > vm.itemsOnChart;

            vm.canScrollLeft = vm.offsetData > 0;
        }

        function createChart($element, vm) {
            var container = $element.find('.charty')[0];

            if (vm.boxPlotChart) {
                vm.boxPlotChart.destroy();
            }

            var dataToChart = mapData(vm);

            vm.boxPlotChart = new BoxPlotChartCreator(container, dataToChart, {
                colors: ConstantsSvc.phaseColors,

                xAxis: {
                    labels: ['Number of', 'programs:']
                },

                yAxis: {
                    labelFormatter: function (x) {
                        return x + ' yr'
                    }
                },

                height: 800
            });

            if (vm.options && vm.options.showNumbers) {
                vm.boxPlotChart.showLabels();
            }

            if (vm.options) {
                vm.boxPlotChart.setXAxisVisibility(!vm.options.hideXAxis);
            }
        }
    }

    function changeOffset(vm, delta) {
        var newOffset = vm.offsetData + delta;

        if (newOffset < 0 || newOffset >= vm.source.length) {
            return false;
        }

        vm.offsetData = newOffset;
        return true;
    }

    function mapData(vm) {
        var data = vm.source.map(function (x) {
            var phases = [x.BoxPlotData.phase1, x.BoxPlotData.phase2, x.BoxPlotData.phase3, x.BoxPlotData.ndabla];

            return {
                source: hidePhases(phases, vm.hiddenPhases),
                number: x.uniqueDrugIndicationQuantity,
                name: x.label
            }
        });

        var dataToChart = data.slice(vm.offsetData, vm.offsetData + vm.itemsOnChart);

        var max = getExtremeValueOfData(dataToChart, Math.max, 'max', Math.ceil);
        return {
            items: dataToChart,
            yRange: {max: max ? max : 1, min: getExtremeValueOfData(dataToChart, Math.min, 'min', Math.floor)}
        };
    }

    function hidePhases(phases, hiddenPhaseIndexes) {
        var result = _.merge([], phases);

        _.forEach(hiddenPhaseIndexes, function (x) {
            result[x - 1] = null;
        });

        return result;
    }

    function getExtremeValueOfData(data, fn, field, round) {
        var maxVales = data.map(function (x) {
            return getExtremeOfBoxBlotData(x.source, fn, field);
        });

        var max = fn.apply(null, maxVales);
        return round(max);
    }

    function getExtremeOfBoxBlotData(source, fn, field) {
        return fn.apply(null, source.map(function (x) {
            return x ? x[field] : 0;
        }));
    }
})();